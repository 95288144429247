import { useContext } from 'react'
import { useCookies } from 'react-cookie'
import { AuthContext } from '../context/AuthContext'
import { useSessionStorage } from 'react-use'

export default function useToken() {
  const { auth_token } = useContext(AuthContext)
  const [cookies] = useCookies(['auth_token', 'user_name'])
  return auth_token || cookies.auth_token || false
}
